import Fetchers from 'core/fetchers';
import ApplicationController from '../application_controller';

class AbTest extends ApplicationController {
  static values = { testName: String }

  // TODO: Verified if this can be removed 01.16.25 -MS
  moreProductsTileClick(event) {
    const url = '/ab-test/finish/also-available-products-tile-click';
    Fetchers.fetchJSON(url, 'POST')
  }

  searchResultsEngagementClick(_event) {
    const dlPageLoadEvt = window.dataLayer?.find(e => e.event === 'pageLoad')
    Fetchers.fetchJSON('/ab-test/finish/search-results-engagement', 'POST', JSON.stringify({
      tag: dlPageLoadEvt?.search__tag,
      query: dlPageLoadEvt?.search__query,
    }))
  }

  cartExpressCheckoutClick() {
    const url = `/ab-test/finish/${this.testNameValue}`;
    Fetchers.fetchJSON(url, 'POST')
  }
}

export default AbTest;
