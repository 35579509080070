import RudderstackController from './rudderstack_controller'

export default class LinkClickedController extends RudderstackController {
  static values = {
    layoutName: String,
    layoutType: String,
    location: String,
    searchTerm: String,
    state: String,
    accountType: String,
  }

  initialize() {
    this.currentLink = null;
    this.eventType = null;
    this.linkMeta = {};
    this.properties = null;
  }

  track(event) {
    this.currentLink = event.currentTarget;
    this.eventType = this.currentLink.dataset.rudderstackEventType;
    this.aggregateProperties();
    const eventType = this.isCTATracking() ? 'CTA' : 'Link';
    const rsEventType = `${eventType} Clicked`;

    RudderstackController.track(rsEventType, this.properties);
  }

  addToProperties(propertyName, propertyValue) {
    if (propertyName && propertyValue) this.properties[propertyName] = propertyValue;
  }

  buildLinkMetaHash() {
    switch (this.locationValue) {
      case 'Shop By Category':
      case 'Featured Categories':
      case 'Time-Tested Topics':
        // The label is the same phrase as the category, but lowercase
        this.linkMeta.l1 = this.currentLink.dataset.linkLabel.toLowerCase();
        break;
      case 'Shop Subcategory':
        this.linkMeta.l1 = window.TeePublic.filterValueforCategory;
        this.linkMeta.l2 = this.currentLink.dataset.linkLabel.toLowerCase();
        break;
      case 'PASF': // Fall through
      case 'Related Tags': // Fall through
      case 'Top Tags':
        try {
          this.linkMeta = JSON.parse(this.currentLink.dataset.linkMeta);
        } catch (error) {
          this.handleError(error)
          this.linkMeta = {};
        }
        // CON-2336 BEGIN: consider whether it's OK to remove searchTerm below, as all search-related information
        //  (including searchTerm) should be obtainable from the page-load event associated w/ these click events by
        //  joining on request_id. (Currently needed by Amplitude which reportedly cannot relate page & click events.)
        this.linkMeta.searchTerm = window.TeePublic.searchTermValue;
        // CON-2336 END
        break;
      default:
        this.linkMeta = null;
        break;
    }
    return this.linkMeta;
  }

  resetProperties() {
      if (window.TeePublic.requestController != 'canvas_landing_page') {
          const formattedLocationValue = this.locationValue.replace(/_/g, '-');
          this.locationValue = formattedLocationValue;
      }

    this.properties = {
      account_type: this.accountTypeValue,
      location: this.locationValue,
      requestController: window.TeePublic.requestController,
      requestAction: window.TeePublic.requestAction,
      requestId: window.TeePublic.requestId,
    };
  }

  getButtonLabel() {
    // We should rely on the data attribute for the button label because using innerText
    // introduces noise to the dataset (e.g. translations, extra spaces, etc).
    return this.currentLink.dataset.buttonLabel || this.currentLink.innerText
  }

  getLinkLabel() {
    // We should rely on the data attribute for the link label because using innerText
    // introduces noise to the dataset (e.g. translations, extra spaces, etc).
    if (this.currentLink.dataset.linkLabel) {
      return this.currentLink.dataset.linkLabel;
    }
    this.handleError('Link label is not translation resistant', this.currentLink);
    return this.currentLink.innerText
  }

  getLinkType() {
    if (this.currentLink.dataset.linkType) {
      return this.currentLink.dataset.linkType;
    }
  }

  getLinkGroup() {
    if (this.currentLink.dataset.linkGroup) {
      return this.currentLink.dataset.linkGroup;
    }
  }

  getHref() {
    // We should rely on the data attribute for the href because some web-based translators
    // impact the href.
    const { href } = this.currentLink.dataset;
    if (href) {
      if (href.startsWith('/')) {
        return `https://www.teepublic.com${href}`;
      }
      return href;
    }
    this.handleError('Href is not translation resistant', this.currentLink);
    return this.currentLink.href;
  }

  isCTATracking() {
    return this.eventType === 'cta'
  }

  setCTATrackingProperties() {
    this.addToProperties('buttonUrl', this.getHref())
    this.addToProperties('buttonLabel', this.getButtonLabel());
    this.addToProperties('linkType', this.getLinkType());
  }

  isLinkTracking() {
    return this.eventType === 'link'
  }

  setLinkTrackingProperties() {
    this.addToProperties('linkLabel', this.getLinkLabel());
    this.addToProperties('linkType', this.getLinkType());
    this.addToProperties('linkUrl', this.getHref());
    this.addToProperties('linkMeta', this.buildLinkMetaHash());
    this.addToProperties('linkGroup', this.getLinkGroup());
  }

  isMarketingTilesCTATracking() {
    return this.isCTATracking() && this.locationValue === 'Marketing Tiles'
  }

  setMarketingTilesCTAProperties() {
    this.addToProperties('moduleLayoutName', this.layoutNameValue);
    this.addToProperties('moduleLayoutType', this.layoutTypeValue);
    this.addToProperties('moduleTilePosition', this.currentLink.dataset.tilePosition);
  }

  aggregateProperties() {
    this.resetProperties();

    if (this.stateValue) this.addToProperties('state', this.stateValue);

    // Event Type Properties
    if (this.isCTATracking()) this.setCTATrackingProperties();
    if (this.isLinkTracking()) this.setLinkTrackingProperties();

    // Location Specific Properties
    if (this.isMarketingTilesCTATracking()) this.setMarketingTilesCTAProperties();
  }
}
