const rsDevMode = window.location.host.includes('localhost')
  || window.location.host.includes('staging')
  || window.location.host.startsWith('127.0');

const storeContextSessionId = () => {
  const defaultExp = new Date();
  defaultExp.setTime(defaultExp.getTime() + (30 * 60 * 1000)); // rs session expires after 30 min by default

  const { rudderanalytics } = window;
  if (!rudderanalytics.getSessionId) return;

  const currentSessionId = rudderanalytics.getSessionId();
  Cookies.set('rs_session_id', currentSessionId, { expires: defaultExp, secure: true });
}

const logProperiesSize = (properties) => {
  const jsonString = JSON.stringify(properties);
  const maxSize = 32000;

  // Assumes UTF-8 encoding
  const sizeInBytes = new TextEncoder().encode(jsonString).length;
  console.log("Size of `RS properties` in bytes:", sizeInBytes, ', max size:', maxSize);

  if (sizeInBytes > maxSize) {
    console.warn("Warning: JSON size exceeds Rudderstack's 32KB limit");
  }
}

const logEvent = (eventType, properties) => {
  if (rsDevMode) {
    console.warn(`Rudderstack not initialized – ${eventType}. Properties: ${JSON.stringify(properties)}`);
    logProperiesSize(properties);
  }
}

const trackSubmissionWithController = (selector) => {
  const submittedEventControllerName = 'rudderstack--checkout-step-submitted';
  document.querySelector(
    `${selector}[data-controller="${submittedEventControllerName}"]`
  )[submittedEventControllerName].track();
}

const renderRudderstackScript = () => {
  const rudderstackJsWriteKey = window.rudderstackEnvVars.write_key;
  const rudderstackDataPlaneUrl = window.rudderstackEnvVars.data_plane_url;
  const rudderstackAnonymousId = window.rudderstackEnvVars.anonymous_id;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){ var r=e.methods[t]; e[r]=e.factory(r)} e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0, r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},
  e.loadJS(),
  e.load("${rudderstackJsWriteKey}", "${rudderstackDataPlaneUrl}"),
  e.setAnonymousId("${rudderstackAnonymousId}")
}();`

  document.body.appendChild(script);
}

export const trackEvent = (name, properties) => {
  try {
    const { rudderanalytics, rudderstackEnvVars: { global_properties } } = window;

    const decoratedProperties = { ...global_properties, ...properties };

    if (rsDevMode) logEvent(name, decoratedProperties);
    if (rudderanalytics) rudderanalytics.track(name, decoratedProperties);
  } catch (error) {
    console.error('RS Error:', error);
  }
}

const RudderstackHelpers = {
  renderRudderstackScript,
  storeContextSessionId,
  trackEvent,
  trackSubmissionWithController,
}

export default RudderstackHelpers;
